import { Accessor, createEffect, createSignal, onCleanup } from "solid-js";

import UFunction from "@repo/utils/UFunction";
import UEventTarget from "@repo/utils-client/UEventTarget";
import UHTMLElement from "@repo/utils-client/UHTMLElement";

import styles from "./createDomRect.module.scss";

declare namespace createDomRect {
  type Scope = (typeof createDomRect.scopes)[number];
  type Options = {
    disabled?: UFunction.Maybe<[], boolean>;
    only?: UFunction.Maybe<[], Scope[]>;
  };
}
function createDomRect({ disabled, only }: createDomRect.Options = {}) {
  let ref: HTMLElement;

  const [rect, setRect] = createSignal(null as null | DOMRect);

  createEffect(() => {
    if (UFunction.unwrap(disabled)) return setRect(null);

    if (!ref)
      throw new Error(
        "createDomRect: Must bind ref to an element when not disabled.",
      );

    function update() {
      setRect(ref.getBoundingClientRect());
    }

    update();

    const scopes = UFunction.unwrap(only) || createDomRect.scopes;

    if (scopes.includes("size")) {
      const observer = new ResizeObserver(update);
      observer.observe(ref);

      onCleanup(() => {
        observer.disconnect();
      });
    }
    if (scopes.includes("scroll")) {
      // Use UHTMLElement.parents instead of UHTMLElement.scrollParents since
      // parents can change from non-scroll parents to scroll parent's and
      // tracking this would cause more of a performance hit
      const parents = UHTMLElement.parents(ref);

      for (const parent of parents) {
        onCleanup(UEventTarget.wrap(parent).on("scroll", update).off);
      }
    }
    if (scopes.includes("position")) {
      const element = document.createElement("div");
      element.className = styles["position-tracker"]!;
      ref.appendChild(element);

      const reposition = () => {
        const { top, left } = element.getBoundingClientRect();
        Object.assign(element.style, {
          marginTop: `${
            Number.parseFloat(element.style.marginTop || "0") - top - 1
          }px`,
          marginLeft: `${
            Number.parseFloat(element.style.marginLeft || "0") - left - 1
          }px`,
        });
      };
      reposition();

      let aligned = true;
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry) return;
          const { intersectionRatio } = entry;

          const visiblePixels = Math.round(intersectionRatio * 4);

          if (visiblePixels === 1) return (aligned = true);

          aligned = false;

          (function frameSync() {
            if (aligned) return;
            setRect(ref.getBoundingClientRect());
            reposition();
            requestAnimationFrame(frameSync);
          })();
        },
        {
          threshold: [0.125, 0.375, 0.625, 0.875],
        },
      );
      observer.observe(element);
      onCleanup(() => {
        element.remove();
        observer.disconnect();
        // Prevent frame sync
        aligned = true;
      });
    }
  });

  return Object.assign(rect, {
    ref(element: HTMLElement) {
      ref = element;
    },
  });
}

createDomRect.scopes = ["size", "scroll", "position"] as const;
createDomRect.cache = new Map<HTMLElement, Accessor<DOMRect>>();

export default createDomRect;
