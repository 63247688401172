import { ComponentProps, JSX } from "solid-js";
import { Dynamic } from "solid-js/web";
import c from "class-c";

import createPropsProvider from "@repo/utils-solid/createPropsProvider";

import Identity from "../flow/Identity";

import styles from "./ContainerButton.module.scss";

declare namespace ContainerButton {
  type Props = Omit<ComponentProps<"div">, "style"> & {
    disabled?: boolean;
    inline?: boolean;
    style?: JSX.CSSProperties;
  };
}

function ContainerButton(_props: D<ContainerButton.Props>) {
  const { inline, style, children, ...props } =
    ContainerButton.PropsProvider.useMerge(_props) satisfies D;

  const onClick = () => (props.disabled ? undefined : props.onClick);

  return (
    <Dynamic
      component={inline ? "span" : "div"}
      class={c`${styles["container-button"]} ${props.class}`}
      style={{
        cursor: onClick() ? "pointer" : undefined,
        ...style,
      }}
      tabIndex={0}
      role="button"
      {...props}
    >
      {children}
    </Dynamic>
  );
}

declare namespace ContainerButton.If {
  type Props = ContainerButton.Props & {
    when: unknown;
  };
}

ContainerButton.If = ({
  when,
  children,
  ...props
}: D<ContainerButton.If.Props>) => {
  return (
    <Dynamic component={when ? ContainerButton : Identity} {...props}>
      {children}
    </Dynamic>
  );
};

ContainerButton.PropsProvider =
  createPropsProvider<ContainerButton.Props>("ContainerButton");

export default ContainerButton;
