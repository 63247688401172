import Case from "@repo/utils/Case";
import Chainable from "@repo/utils/Chainable";
import { msIn } from "@repo/utils/Duration";
import UObject from "@repo/utils/UObject";
import UType from "@repo/utils/UType";

namespace Css {
  export type Var = `var(--${string})`;
  export const varMap = Chainable.create(
    Chainable.wrapFactory(
      ({ raw = false }) =>
        <Map extends Record<string, string | number>>(
          map: Map,
        ): {
          [Key in keyof Map as `--${Case.ToKebab<UType.Narrow<Case.FromCamel<UType.Narrow<Key, string>>, string[]>>}`]: Map[Key];
        } =>
          UObject.transform(map, ([key, value]: any) => [
            `--${key}`,
            raw ? JSON.stringify(value) : value,
          ]) as any,
    ),
    { raw: { raw: true } },
  );

  export const computedColor = (color: string) =>
    getComputedStyle(document.body).getPropertyValue(color);

  export const parseTime = (timeValue: string) => {
    if (timeValue.endsWith("ms")) return +timeValue.slice(0, -2);
    if (timeValue.endsWith("s")) return +timeValue.slice(0, -1) * msIn.second;
    return NaN;
  };
}

export default Css;
