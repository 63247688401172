import { ComponentProps, JSX } from "solid-js";
import c from "class-c";
import { PropsFor } from "solid-u";

import createPropsProvider from "@repo/utils-solid/createPropsProvider";

import styles from "./ListTile.module.scss";

declare namespace ListTile {
  interface Props {
    class?: string;
    leading?: JSX.Element;
    label?: JSX.Element;
    title: JSX.Element;
    subtitle?: JSX.Element;
    trailing?: JSX.Element;
    propsFor?: PropsFor<{
      leading?: ComponentProps<"div">;
      label?: ComponentProps<"div">;
      title?: ComponentProps<"header">;
      subtitle?: ComponentProps<"div">;
      trailing?: ComponentProps<"div">;
    }>;
    align?: "center";
  }
}

function ListTile(_props: ListTile.Props) {
  const {
    class: className,
    leading,
    label,
    title,
    subtitle,
    trailing,
    propsFor: _propsFor,
    align,
  } = ListTile.PropsProvider.useMerge(_props) satisfies D;

  const propsFor = PropsFor.createHandler(() => _propsFor);

  return (
    <div
      class={c`${styles["list-tile"]} ${align && styles[`align:${align}`]} ${className}`}
    >
      {leading && <div {...propsFor("leading")}>{leading}</div>}
      <main>
        {label && (
          <div
            {...propsFor("label")}
            class={c`text:mini mb-0.5 ${propsFor("label").class}`}
          >
            {label}
          </div>
        )}
        <header {...propsFor("title")}>{title}</header>
        {subtitle && (
          <div
            {...propsFor("subtitle")}
            // TODO: Implement class overriding for atomic classes
            class={c`text:small color:moderate mt-0.5 ${
              propsFor("subtitle").class
            }`}
          >
            {subtitle}
          </div>
        )}
      </main>
      {trailing && (
        <div
          {...propsFor("trailing")}
          class={c`${styles.trailing} ${propsFor("trailing").class}`}
        >
          {trailing}
        </div>
      )}
    </div>
  );
}

ListTile.PropsProvider = createPropsProvider<ListTile.Props>("ListTile");

export default ListTile;
