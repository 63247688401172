import { JSX, onCleanup, onMount, Show } from "solid-js";
import c from "class-c";
import { Motion, Presence } from "solid-motionone";

import USolid from "@repo/utils-solid/USolid";

import createDomRect from "../signals/createDomRect";

import styles from "./FloatingWithAnchor.module.scss";

declare namespace FloatingWithAnchor {
  type Props = {
    class?: string;
    anchor: JSX.Element;
    show: boolean;
    onClickOutside?(): void;
    position?: "bottom" | "bottom-left";
    children?: JSX.Element;
  };
}

function FloatingWithAnchor({
  class: className,
  anchor,
  position = "bottom",
  show,
  onClickOutside,
  children,
}: D<FloatingWithAnchor.Props>) {
  const resolvedAnchor = USolid.children(() => anchor);

  onMount(() => {
    if (resolvedAnchor() instanceof Array)
      throw new Error("`anchor` must only have one element.");

    if (!(resolvedAnchor() instanceof HTMLElement))
      // In order to use createDomRect to track positioning
      throw new Error("`anchor` must be instance of HTMLElement.");
  });

  const rect = createDomRect();

  rect.ref(resolvedAnchor() as any);

  let menuRef: HTMLDivElement;
  onMount(() => {
    const onClick = (e: MouseEvent) => {
      if (menuRef && !menuRef.contains(e.target as Node)) onClickOutside?.();
    };
    document.body.addEventListener("click", onClick);
    onCleanup(() => document.body.removeEventListener("click", onClick));
  });

  return (
    <>
      {resolvedAnchor()}
      <Presence>
        <Show when={show && rect()}>
          <Motion.div
            ref={menuRef!}
            class={c`${styles.floating} ${className}`}
            initial={{ opacity: 0, y: -8 }}
            style={{
              top: `${rect()!.bottom}px`,
              ...(position === "bottom-left"
                ? {
                    right: `${window.innerWidth - rect()!.right}px`,
                  }
                : {
                    left: `${rect()!.left}px`,
                  }),
              width:
                position === "bottom" ? `${rect()!.width}px` : "fit-content",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{ opacity: 0, y: -8 }}
            transition={{
              duration: 0.2,
              top: { duration: 0 },
              left: { duration: 0 },
              width: { duration: 0 },
            }}
          >
            {children}
          </Motion.div>
        </Show>
      </Presence>
    </>
  );
}

export default FloatingWithAnchor;
