import { createSignal, onMount } from "solid-js";

import { nextFrame } from "@repo/utils-client/animation";

export default function createInitial() {
  const [initial, setIntial] = createSignal(true);

  onMount(() => {
    nextFrame(() => {
      setIntial(false);
    });
  });

  return initial;
}
